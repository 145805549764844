import React from "react";
import { Link } from "react-router-dom";
import Main from "../components/Main";
import Sidebar from "../components/Sidebar";

import "./styles/CurriculumVitae.css";

class Badges extends React.Component {
  state = {
    loading: true,
    error: null,
    data: {
      profile: {
        name: "Eustaquio Quispe Camino",
        occupation: "Full Stack Developer",
        detail:
          "Soy programador con más de 7 años de experiencia en desarrollo de aplicaciones y sistemas web con frameworks de PHP, JavaScript, .NET y otros por el lado del servidor, usando bases de datos MySql, SQLite y SQL Server también el uso AngularJS, ReactJS y otros por el lado del cliente. \n Me gusta estar siempre al dia con temas de tecnología, especialmente los que involucran al ambiente en el que trabajo.",
      },
      contacts: [
        {
          url: "mailto:eus.dev6@gmail.com",
          label: "eus.dev6@gmail.com",
          type: "fa fa-envelope",
        },
        {
          url: "tel:76929116",
          label: "76929116",
          type: "fa fa-phone",
        },
        {
          url: "https://linkedin.com/in/eustaquio-quispe",
          label: "eustaquio-quispe",
          type: "fa fa-linkedin",
        },
        {
          url: "https://github.com/eus-dev7",
          label: "github.com/eus-dev7",
          type: "fa fa-github",
        },
        {
          url: "https://github.com/eus-dev8",
          label: "github.com/eus-dev8",
          type: "fa fa-github",
        },
        {
          url: "https://twitter.com/euss_10",
          label: "@euss_10",
          type: "fa fa-twitter",
        },
        {
          url: "https://www.instagram.com/eus.qc/",
          label: "@eus.qc",
          type: "fa fa-instagram",
        },
        {
          url: "https://www.eusqc.com",
          label: "www.eusqc.com",
          type: "fa fa-globe",
        },
      ],
      education: [
        {
          formation: "Ingeniería de sistemas",
          institution: "Universidad Salesiana de Bolivia",
          time: "2012 - 2016",
        },
        {
          formation: "Educacion escolar",
          institution: "U. E. German Bush",
          time: "2003 - 2011",
        },
      ],
      languages: [
        { language: "Español", level: "Nativo" },
        { language: "Inglés", level: "Escrito" },
        { language: "Aymara", level: "Básico" },
      ],
      interests: [
        {
          insterest: "Programación",
          detail:
            "Solucionar problemas cotidianos con tecnologías de información.",
        },
        {
          insterest: "Robótica",
          detail:
            "Programación de microcontroladores y placas de programación.",
        },
        {
          insterest: "Internet de las cosas",
          detail:
            "Programación de placas con sensores para la interconexión de objetos de uso cotidiano.",
        },
      ],

      experiences: [
        {
          name: "Luces Nuevas, Cochabamba",
          time: "2019 - presente",
          job_title: "Desarrollador de aplicaciones y sistemas web",
          detail: "",
        },
        {
          name: "Octopus Technologies, Cochabamba",
          time: "2016 - 2019",
          job_title: "Desarrollador web",
          detail: "",
        },
        {
          name: "Acedevel, Cochabamba",
          time: "2014 - 2016",
          job_title: "Desarrollador web",
          detail: "",
        },
      ],

      projects: [
        {
          name: "Gesname",
          detail:
            "Sistema web de gestión de proyectos de software desarrollado en PHP, con base de datos MySql y javascript, JQquery, Bootstrap para el lado del cliente.",
        },
        {
          name: "Sistema estimación de costos Cocomo II",
          detail:
            "Aplicación de escritorio para estimación de costos de proyectos software mediante el modelo de estimación Cocomo II, desarrollado en CSharp con base de datos MySql.",
        },
        {
          name: "Teledirigido controlado por aplicación web",
          detail:
            "Teledirigido montado con Raspberry Pi, Modulo Driver L298 y WebCam controlado desde una aplicación web desarrollado en PHP del lado del servidor, javascript, JQuery y css para la interfaz del cliente, y Python para manejar los pines de la Raspberry Pi.",
        },
        {
          name: "Teledirigido controlado por app Android",
          detail:
            "Teledirigido montado en un Arduino, y módulo L298 controlado mediante bluetooth con App Android desarrollado en App inventor.",
        },
      ],

      skills: [
        { name: "JavaScript", level: 75 },
        { name: "CSharp", level: 70 },
        { name: "PHP", level: 65 },
        { name: "C", level: 40 },
        { name: "Python", level: 40 },
        { name: "Java (Android Studio)", level: 50 },
        { name: "Kotlin", level: 70 },
        { name: "CSS3", level: 50 },
      ],
      frameworks: [
        { name: "ReactJS", level: 75 },
        { name: ".NET", level: 65 },
        { name: "Xamarin", level: 50 },
        { name: "ElectronJS", level: 60 },
        { name: "Laravel", level: 58 },
        { name: "Flutter", level: 50 },
        { name: "AngularJS", level: 50 },
        { name: "VueJS", level: 45 },
      ],
      data_bases: [
        { name: "MySql", level: 80 },
        { name: "SQLite", level: 70 },
        { name: "SQLServer", level: 60 },
        { name: "MongoDb", level: 50 },
      ],
      boards: [
        { name: "Arduino", level: 65 },
        { name: "Raspberry Pi", level: 63 },
        { name: "Microcontroladores", level: 60 },
      ],
      repositories: ["GitHub", "Bitbucket"],
      servers: ["Apache", "Nginx"],
      operating_systems: [
        "MacOS High Sierra, MacOS Catalina",
        "Parrot Security OS, Kali Linux, Ubuntu, Arch Linux",
        "Windows xp, 7, 8 y 10",
        "Raspbian, Raspberry Pi OS ",
      ],
    },
  };

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <Sidebar
            profile={this.state.data.profile}
            contacts={this.state.data.contacts}
            education={this.state.data.education}
            languages={this.state.data.languages}
            interests={this.state.data.interests}
          />
          <Main
            profile={this.state.data.profile}
            experiences={this.state.data.experiences}
            projects={this.state.data.projects}
            skills={this.state.data.skills}
            frameworks={this.state.data.frameworks}
            data_bases={this.state.data.data_bases}
            boards={this.state.data.boards}
            repositories={this.state.data.repositories}
            servers={this.state.data.servers}
            operating_systems={this.state.data.operating_systems}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Badges;
