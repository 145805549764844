import React from "react";
import { Link } from "react-router-dom";
import "./styles/Sidebar.css";

class Sidebar extends React.Component {
  render() {
    return (
      <div className="SidebarWrapper">
        <div className="ProfileContainer">
          <img
            className="ProfilePhoto"
            src="assets/images/profile.png"
            alt=""
          />
          <h1 className="ProfileName">{this.props.profile.name}</h1>
          <h3 className="Tagline">{this.props.profile.occupation}</h3>
        </div>

        <div className="ContactContainer ContainerBlock">
          <ul className="list-unstyled ContactList">
            {this.props.contacts.map((contact, index) => {
              return (
                <li key={index} className="constact">
                  <i className={contact.type}></i>
                  <Link to={{ pathname: contact.url }} target="_blank">
                    {contact.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="EducationContainer ContainerBlock">
          <h2 className="ContainerBlockTitle">FORMACIÓN ACADÉMICA</h2>
          {this.props.education.map((education, index) => {
            return (
              <div key={index} className="Item">
                <h4 className="Degree">{education.formation}</h4>
                <h5 className="Meta">{education.institution}</h5>
                <div className="Time">{education.time}</div>
              </div>
            );
          })}
        </div>

        <div className="LanguagesContainer ContainerBlock">
          <h2 className="ContainerBlockTitle">IDIOMAS</h2>
          <ul className="list-unstyled interests-list">
            {this.props.languages.map((language, index) => {
              return (
                <li key={index}>
                  {language.language}{" "}
                  <span className="LangDesc">({language.level})</span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="InterestsContainer ContainerBlock">
          <h2 className="ContainerBlockTitle">INTERESES</h2>
          <ul className="list-unstyled InterestsList">
            {this.props.interests.map((interest, index) => {
              return (
                <li key={index}>
                  <strong>{interest.insterest}:</strong> {interest.detail}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
