import React from "react";
import { ProgressBar } from "react-bootstrap";

class Main extends React.Component {
  render() {
    return (
      <div className="main-wrapper">
        <section className="section summary-section">
          <h2 className="section-title">
            <span className="icon-holder">
              <i className="svg-inline--fa fa fa-user fa-w-14"></i>
            </span>
            HOJA DE VIDA
          </h2>
          <div className="summary">
            <p>{this.props.profile.detail}</p>
          </div>
        </section>
        <section className="section experiences-section">
          <h2 className="section-title">
            <span className="icon-holder">
              <i className="svg-inline--fa fa fa-briefcase"></i>
            </span>
            EXPERIENCIA LABORAL
          </h2>
          {this.props.experiences.map((exp, index) => (
            <div className="item" key={index}>
              <div className="meta">
                <div className="upper-row">
                  <h3 className="job-title">{exp.job_title}</h3>
                  <div className="time">{exp.time}</div>
                </div>
                <div className="company">{exp.name}</div>
              </div>
              <div className="details">
                <p></p>
                <p></p>
              </div>
            </div>
          ))}
        </section>
        <section className="section projects-section">
          <h2 className="section-title">
            <span className="icon-holder">
              <i className="svg-inline--fa fa fa-archive"></i>
            </span>
            PROYECTOS PERSONALES
          </h2>
          {this.props.projects.map((project, index) => (
            <div className="item">
              <span className="project-title">
                <a href="#" target="_blank">
                  <strong>{project.name}</strong>
                </a>
              </span>
              -<span className="project-tagline">{project.detail}</span>
            </div>
          ))}
        </section>
        <section className="skills-section section">
          <h2 className="section-title">
            <span className="icon-holder">
              <i className="svg-inline--fa fa fa-rocket"></i>
            </span>
            HABILIDADES TECNOLÓGICAS
          </h2>
        </section>
        <div className="skillset">
          <h6>Conocimientos en:</h6>
          {this.props.skills.map((skill, index) => (
            <div className="item" key={index}>
              <h3 className="level-title">{skill.name}</h3>
              <div>
                <ProgressBar now={skill.level} variant="info" />
              </div>
            </div>
          ))}

          <h6>Experiencia con Frameworks:</h6>
          {this.props.frameworks.map((framework, index) => (
            <div class="item" key={index}>
              <h3 class="level-title">{framework.name}</h3>
              <div>
                <ProgressBar now={framework.level} variant="info" />
              </div>
            </div>
          ))}
          <h6>Gestores de base de datos manejados:</h6>
          {this.props.data_bases.map((database, index) => (
            <div class="item" key={index}>
              <h3 class="level-title">{database.name}</h3>
              <div>
                <ProgressBar now={database.level} variant="info" />
              </div>
            </div>
          ))}
          <h6>Placas de desarrollo:</h6>
          {this.props.boards.map((board, index) => (
            <div class="item" key={index}>
              <h3 class="level-title">{board.name}</h3>
              <div>
                <ProgressBar now={board.level} variant="info" />
              </div>
            </div>
          ))}
          <h6>Manejo de repositorios</h6>
          {this.props.repositories.map((repo, index) => (
            <div class="item" key={index}>
              <h3 class="level-title">- {repo}</h3>
            </div>
          ))}
          <h6>Servidores manejados</h6>
          {this.props.servers.map((server, index) => (
            <div class="item" key={index}>
              <h3 class="level-title">- {server}</h3>
            </div>
          ))}
          <h6>Sistemas operativos manejados</h6>
          {this.props.operating_systems.map((op, index) => (
            <div class="item" key={index}>
              <h3 class="second-level-title">- {op}</h3>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Main;
